import { render, staticRenderFns } from "./recycleBin.vue?vue&type=template&id=262a8b20&scoped=true&"
import script from "./recycleBin.vue?vue&type=script&lang=js&"
export * from "./recycleBin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "262a8b20",
  null
  
)

export default component.exports