<template>
    <div class="page">
        <el-form class="query-form" :inline="true" size="small" ref="searchForm" :model="searchForm"
                 label-width="100px">
            <el-form-item prop="name" label="关键字查询：">
                <el-input clearable maxlength="66" v-model="searchForm.name" @keyup.enter.native="submitForm('searchForm')"
                          placeholder="资源名称或编号"></el-input>
            </el-form-item>
            <el-form-item prop="resourceType" label="资源类型：">
                <el-select clearable v-model="searchForm.resourceType" placeholder="请选择资源类型">
                    <el-option
                            v-for="item in $dictUtils.getDictList('resource_type')"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="searchDates" label="日期：">
                <el-date-picker
                        v-model="searchForm.searchDates"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('searchForm')" icon="el-icon-search">查询</el-button>
                <el-button @click="resetForm('searchForm')" icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white">
            <div class="flex_b_c">
                <AdvancedQuery ref="advancedQuery" :moduleId="moduleId" @getDataList="atlGetDataList"></AdvancedQuery>
                <div>
                    <el-button icon="el-icon-refresh" type="primary" size="small" @click="areduction()">批量还原
                    </el-button>
                    <el-button icon="el-icon-delete" type="danger" size="small" @click="adelete()">彻底删除
                    </el-button>
                </div>
            </div>
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    ref="filterTable"
                    size="small"
                    height="calc(100vh - 329px)"
                    @selection-change="selectionChangeHandle"
                    class="table" row-key='id'>
                <el-table-column
                        type="selection"
                        width="55" :reserve-selection='true'>
                </el-table-column>
                <el-table-column
                        prop="id"
                        show-overflow-tooltip sortable
                        label="资源编号">
                </el-table-column>
                <el-table-column
                        prop="resource_name"
                        show-overflow-tooltip sortable
                        label="资源名称">
                </el-table-column>
                <el-table-column
                        prop="resource_type"
                        show-overflow-tooltip sortable
                        label="资源类型">
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("resource_type",scope.row.resource_type,'-')}}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="on_collection"
                        show-overflow-tooltip sortable
                        label="是否藏品">
                    <template slot-scope="scope">
                        <span>{{scope.row.on_collection ===0 ? '否' : '是'}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="updateUserName"
                        show-overflow-tooltip sortable
                        label="更新人员">
                </el-table-column>
                <el-table-column
                        prop="update_time"
                        show-overflow-tooltip sortable
                        label="更新时间">
                </el-table-column>
                <el-table-column
                        fixed="right"
                        width="200"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" v-show="hasPermission('admin:resources:view')"
                                   @click="see(scope.row)">详情
                        </el-button>
                        <el-button type="text" size="small" v-show="hasPermission('admin:recycle:reduction')"
                                   @click="areduction(scope.row.id)">还原
                        </el-button>
                        <el-button type="text" size="small" v-show="hasPermission('admin:recycle:del')"
                                   @click="adelete(scope.row.id)">彻底删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>

        </div>
        <!--列表设置拖拽排序弹窗-->
        <DraggablePop :moduleId="moduleId" :setShow="setShow"
                      @getTbList="getTbList"></DraggablePop>
        <!--        三维-->
        <!--查看-->
        <ViewThreeForm ref="viewThreeForm"></ViewThreeForm>

        <!--查看-->
        <ViewForm ref="viewForm"></ViewForm>
    </div>
</template>

<script>
    import DraggablePop from '@/components/draggable/draggablePop2' // 导入排序弹窗
    import AdvancedQuery from '@/components/advancedQuery/advancedQuery' // 导入高级筛选弹窗
    import ViewThreeForm from '../gather/resource/three/viewThreeForm' // 三维查看弹窗
    import ViewForm from '../gather/resource/components/viewForm' // 查看弹窗
    export default {
        name: "recycleBin",
        components: {DraggablePop, AdvancedQuery, ViewThreeForm, ViewForm},
        data() {
            return {
                onScene: false, // 是否正在使用场景
                dataListQuery: [],
                searchForm: {
                    name: '',//资源名称或编号
                    searchDates: ['', ''], //时间
                    resourceType: '',//资源类型
                },
                moduleId: '948168391166787584',   // 当前请求拖拽排序数据id
                setShow: false,
                loading: false,
                dataList: [],//列表
                dataListSelections: [],//多选列表
                pageNo: 1,
                pageSize: 10,
                total: 0,
                options: [
                    {
                        value: '',
                        label: '全部',
                    },
                    {
                        value: 1,
                        label: '图片',
                    },
                    {
                        value: 2,
                        label: '文档',
                    },
                    {
                        value: 3,
                        label: '视频',
                    },
                    {
                        value: 4,
                        label: '音频',
                    },
                ],
            }
        },
        mounted() {
            this.getList();
        },

        methods: {
            //进入页面数据请求
            getList() {
                if (this.onScene || (this.dataListQuery.queryConditions != null && this.dataListQuery.queryConditions.length > 0)) {
                    this.getDataList(this.dataListQuery)
                    this.loading = false
                    return
                }
                if (!this.searchForm.searchDates) {
                    this.searchForm.searchDates = ['', '']
                }
                this.dataListQuery.sceneId = ''
                this.loading = true
                this.$axios(this.api.resources.recoveryList, {
                    'current': this.pageNo,
                    'size': this.pageSize,
                    'delFlag': 1,
                    'type': this.searchForm.resourceType,
                    'name': this.searchForm.name,
                    'startTime': this.searchForm.searchDates[0],
                    'endTime': this.searchForm.searchDates[1]
                }, 'get').then(res => {
                    if (res.status) {
                        this.loading = false;
                        this.dataList = res.data.records;
                        if (this.dataList.length == 0 && this.pageNo > 1) {
                            this.pageNo--
                            this.getList()
                        }
                        this.total = parseInt(res.data.total);
                    }
                })
            },


            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },

            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                if (this.onScene) {
                    this.getDataList(this.dataListQuery)
                } else {
                    this.getList();
                }
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                if (this.onScene) {
                    this.getDataList(this.dataListQuery)
                } else {
                    this.getList();
                }
            },

            //删除
            adelete(id) {
                if (!id && !this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                }).join(',')

                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //删除接口
                    this.$axios(this.api.resources.remove, {
                        'delFlag': 2, 'ids': ids
                    }, 'post').then(res => {
                        if (res.status) {
                            this.$message.success('彻底删除成功')
                            this.getList();
                        } else {
                            this.$message.error('彻底删除失败')
                        }
                    })
                })
            },

            //还原
            areduction(id) {
                if (!id && this.dataListSelections.length == 0) {
                    this.$message.warning('请至少选择一条数据')
                } else {
                    let ids = id || this.dataListSelections.map(item => {
                        return item.id
                    }).join(',')
                    this.$confirm('此操作将还原该数据, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$axios(this.api.resources.remove, {
                            'delFlag': 0, 'ids': ids
                        }, 'post').then(res => {
                            if (res.status) {
                                this.$message.success('还原成功')
                                this.getList();
                            }
                        })
                        this.getList();
                    }).catch(() => {
                    });
                }
            },

            //查询
            submitForm() {
                this.pageNo = 1;
                this.pageSize = 10;
                this.getList();
            },

            //重置
            resetForm(formName) {
                this.dataListSelections = []
                this.$refs.filterTable.clearSelection()
                this.searchForm.resourceType = ''
                this.searchForm.name = ''
                this.searchForm.searchDates = ['', '']
                this.onScene = false
                this.pageNo = 0
                this.onScene = false
                this.dataListQuery = []
                this.$refs.advancedQuery.addForm.sceneId = ''
                // this.$refs.searchForm.resetFields()
                this.$nextTick(() => {
                    this.getList()
                })
            },

            //单个数据查看
            see(row) {
                if (row.resource_type == 0) {
                    this.$refs.viewThreeForm.init(row,1);
                } else {
                    this.$refs.viewForm.init(row.id, row.resource_type, '948974400588218368',1);
                }
            },

            // 接收子组件（排序弹窗）传过来的值
            getTbList(data, state) {
                this.setShow = state;
            },
            // 高级查询
            atlGetDataList(data) {
                this.pageNo = 0
                this.getDataList(data)
            },

            // 获取高级查询条件
            getDataList(data) {
                if (data != null && data.sceneId != null && data.sceneId != '') {
                    this.onScene = true
                } else {
                    this.onScene = false
                }
                this.dataListQuery = data
                data.size = this.pageSize,
                    data.current = this.pageNo,
                    data.type = this.searchForm.resourceType
                data.delFlag = 1
                data.searchNumOrName = this.searchForm.name
                data.startTime = this.searchForm.searchDates[0]
                data.endTime = this.searchForm.searchDates[1]
                this.$axios(this.api.rcPage.recoveryQueryResource, JSON.stringify(data), 'post').then(res => {
                    if (res && res.status) {
                        this.dataList = res.data.records
                        this.total = parseInt(res.data.total);
                    }
                })
            },

            // 设置
            setTb() {
                this.setShow = true;
            },
        }
    }
</script>

<style scoped>

</style>
